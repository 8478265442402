<template>
	<div>
        <load v-if="loader" color="#2474b2" size="70" />
        <div v-else>
            <div>
                <ValidationObserver ref="form" >
                    <form class="modal_login row">
                        <div class="col-md-12 my-2">
                            <label class="label_default mb-0">
                                Escolha a
                                <strong>Unidade</strong>
                            </label>
                        </div>
                        <ValidationProvider
                            v-slot="{ errors, ariaMsg, classes }"
                            rules="required"
                            name="Unidade"
                            tag="div"
                            :bails="false"
                            class="col-md-12 my-1"
                        >
                            <select class="input_default form-control" v-model="unidadeSelect" :class="classes">
                                <option :value="{tenancyName:'master',id:0}">MASTER</option>
                                <option :value="item" v-for="(item,index) in unidades" :key="index">{{item.tenancyName}}</option>
                            </select>
                            <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </form>
                </ValidationObserver>
            </div>
            <div class="login_cpf">
                <ValidationObserver ref="form">
                    <form class="modal_login row" @submit.prevent="logCpf">
                        <div class="col-md-12 my-2">
                            <label class="label_default mb-0">
                                Qual seu
                                <strong>CPF ?</strong>
                            </label>
                        </div>

                        <ValidationProvider
                            v-slot="{ classes ,validate}"
                            rules="required|min:11"
                            name="CPF"
                            tag="div"
                            :bails="false"
                            class="col-md-12 my-1"
                        >
                            <the-mask
                                type="text"
                                id="selectLogin"
                                class="input_default form-control"
                                mask="###.###.###-##"
                                @blur.native="validate($event)"
                                v-model="CPF"
                                placeholder="000.000.000-00"
                                :class="classes"
                                :masked="false"
                            />
                        </ValidationProvider>
                        <div class="col-md-12 my-1">
                            <button type="submit" class="btn_default px-2 py-2" :disabled="disabled" id="buttonSubmitLogin">
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                    v-show="disabled"
                                ></span>
                                {{ !disabled ? "Continuar" : "" }}
                            </button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
	</div>
</template>

<script>
	import { TheMask } from "vue-the-mask";
    import load from "@/components/utils/_Load";
	export default {
		components: { TheMask, load },

		data() {
			return {
				disabled: false,
				CPF: "",
                disabled: false,
				unidades: [],
				unidadeSelect: "",
				loader: true
			};
		},
		mounted() {
			// const host = window.location.host.split(".");
			// if (host.length == 5) {
			// 	this.getTenant(host[0]);
			// }
            this.$store
				.dispatch("getTenants")
				.then(resolve => {
					this.unidades = resolve;
					this.loader = false;
				})
				.catch(reject => {});
		},
		methods: {
            logCpf() {
				this.$refs.form.validate().then(success => {
					if (success) {
						this.$store.commit("set_tenant", this.unidadeSelect);
						this.$nextTick(() => {
							this.loginStep()
						});
					}
				});
			},
			loginStep() {
				this.$refs.form.validate().then(success => {
					if (success) {
						this.disabled = true;
						this.$store.dispatch("cpfForLogin", this.CPF).then(resolve => {
							if (!resolve.erro) {
								this.disabled = false;
								this.$parent.gratientAnimator(
									"linear-gradient(135deg, #e0a638 0%, #f38235 100%)"
								);
								this.$router.push("/senha");
							} else {
								this.$notify({
									group: "erros",
									type: "error",
									text: `<i class="icon ion-close-circled"></i>${resolve.message}`
								});
								this.disabled = false;
							}
						});
					}
				});
			},
		},
		computed: {},
        watch: {
			unidadeSelect: function() {}
		}
	};
</script>


<style scoped>
label{
    font-size: 16px;
}
span{
    font-size: 12px;
    padding-top: 3px;
}
@media screen and (max-width:767px){
	.input_default{
		width: 100%;
		text-align: center;
		background-color: #ffffff !important;
		font-weight: 500 !important;
		border: 1px solid #98C4E6;
		border-radius: 7px !important;
		height: calc(2.5em + 0.75rem + 2px);
	}
	#buttonSubmitLogin{
		margin-top: 5%;
		height: 40px;
		width: 100%;
		border-radius: 10px;
		font-weight: 500;
		border: 1px solid #2474b2;
		background-color: #2474b2;
		color: #ffff;
		box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
		transition: all 0.2s linear;
		font-size: 18px;
	}
}
</style>
